import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Table, Input, Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Badge } from 'reactstrap';
import { FaDownload, FaWhatsapp } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import debounce from 'lodash.debounce';

const OpenHouseUpi = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    phone: '',
    email: '',
    school: '',
    grades: '',
    majors: '',
    aware: '',
    informationSource: '',
    interestLevel: '',
    interestReasons: '',
    desiredProgram: '',
    selectedMajor: '',
    enrollmentPlan: '',
    eventFeedback: '',
    campusImpression: '',
    consideration: '',
    wantMoreInfo: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([
    ...Object.keys(filters), // Kolom yang ada saat ini
    'whatsapp', // Kolom untuk tombol Chat
  ]);
  const [selectedColumns, setSelectedColumns] = useState(Object.keys(filters)); // Default to all columns
  const [modal, setModal] = useState(false);

  // Modify the headers here
  const columnHeaders = {
    name: 'Full Name',
    phone: 'Phone Number',
    email: 'Email Address',
    school: 'Asal Sekolah',
    grades: 'Kelas',
    majors: 'Jur. Sekolah',
    aware: 'Tau UPI Sebelumnya?',
    informationSource: 'Dari mana dapat Info?',
    interestLevel: 'Tertarik Masuk UPI?',
    interestReasons: 'Kenapa Tertarik?',
    desiredProgram: 'Pilih Fakultas',
    selectedMajor: 'Pilih Jurusan',
    enrollmentPlan: 'Berencana Daftar UPI?',
    eventFeedback: 'Open House UPI?',
    campusImpression: 'Kesan Kampus UPI YPTK',
    consideration: 'Pertimbangan Memilih Kampus',
    wantMoreInfo: 'More Info?',
    whatsapp: 'Chat', // New column header for WhatsApp Chat
    whatsappMessage: 'Message WA' // New column for WA Message button
  };

  // Format phone number to 62 if starts with 0, or remove + from +62
  const formatPhoneNumber = (phone) => {
    if (!phone) return ''; // Add check if phone is empty
    if (phone.startsWith("0")) {
      return "62" + phone.slice(1); // Replace leading 0 with 62
    }
    if (phone.startsWith("+62")) {
      return phone.slice(1); // Remove '+' from +62
    }
    return phone;
  };

  // Function to download data as Excel file with selected columns
  const downloadExcel = () => {
    const filteredDownloadData = filteredData.map((item) =>
      selectedColumns.reduce((acc, col) => {
        acc[col] = item[col];
        return acc;
      }, {})
    );
    const ws = XLSX.utils.json_to_sheet(filteredDownloadData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "OpenHouseData");
    XLSX.writeFile(wb, "OpenHouseData.xlsx");
  };

  // Fetch data from API
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://app.rlagency.id/apiopenhouse/get-data-kuisioner.php"
      );

      // Format phone number before storing
      const formattedData = response.data.map((item) => ({
        ...item,
        phone: formatPhoneNumber(item.phone), // Format phone
      }));

      setData(formattedData);
      setFilteredData(formattedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  // Handle filter change for each column
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Filter data based on all column filters
  const filterData = debounce(() => {
    let filtered = data;

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtered = filtered.filter((item) =>
          item[key].toLowerCase().includes(filters[key].toLowerCase())
        );
      }
    });

    setFilteredData(filtered);
  }, 300); // Debounced with 300ms delay

  // Effect to apply filters when filter state changes
  useEffect(() => {
    filterData();
  }, [filters, data]);

  // Effect to fetch data on initial load
  useEffect(() => {
    fetchData();
  }, []);

  // Toggle modal visibility
  const toggleModal = () => setModal(!modal);

  // Handle column selection for download
  const handleColumnChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedColumns((prev) => [...prev, value]);
    } else {
      setSelectedColumns((prev) => prev.filter((col) => col !== value));
    }
  };

  // Memoized filtered data for performance optimization
  const memoizedFilteredData = useMemo(() => {
    let filtered = data;

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtered = filtered.filter((item) =>
          item[key].toLowerCase().includes(filters[key].toLowerCase())
        );
      }
    });

    return filtered;
  }, [data, filters]);

  // Handle WhatsApp message
  const handleWhatsApp = (phone, name) => {
    window.open(
      `https://wa.me/${phone}?text=${encodeURIComponent(`Assalamualaikum ${name}...`)}`,
      '_blank'
    );
  };

  const getColumnMinWidth = (key) => {
    switch (key) {
      case 'school':
      case 'desiredProgram':
      case 'selectedMajor':
        return '150px';
      case 'grades':
        return '70px';
      case 'majors' :
        return '50px';
      default:
        return 'auto';
    }
  };  

  
  return (
    <div style={{ padding: '20px' }}>
      {/* Header and Download Button with Row Count */}
      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h3>Open House Registration Data</h3>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Badge color="secondary" style={{ marginRight: '10px', backgroundColor:'black' }}>
            {memoizedFilteredData.length} Records
          </Badge>
          <Button color="primary" onClick={toggleModal}><FaDownload /> Download Excel</Button>
        </div>
      </div>

      {/* Modal for column selection */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Select Columns for Download</ModalHeader>
        <ModalBody>
          {columns.map((column) => (
            <div key={column}>
              <Input
                type="checkbox"
                value={column}
                checked={selectedColumns.includes(column)}
                onChange={handleColumnChange}
                id={column}
              />
              <label htmlFor={column} style={{ marginLeft: '5px' }}>
                {columnHeaders[column] || column}
              </label>
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          {/* <Button color="secondary" onClick={toggleModal}>Cancel</Button> */}
          <Button color="primary" onClick={downloadExcel}>Download</Button>
        </ModalFooter>
      </Modal>

      {/* Loading spinner */}
      {isLoading ? (
        <Spinner color="primary" />
      ) : (
        // <div className="table-container" style={{ maxHeight: '700px', overflowY: 'auto', position: 'relative' }}>
        <div style={{ maxHeight: '700px', overflowY: 'auto', position:'sticky', top:'0', zIndex:100}}>    
          {/* Table with Styling */}
          <Table bordered hover responsive style={{ borderRadius: '10px', backgroundColor: '#fff' }}>
          <thead style={{ backgroundColor: '#f8f9fa' }}>
              <tr>
                {/* Kolom nomor urut */}
                <th
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    padding: '10px',
                    minWidth: '30px',
                    verticalAlign: 'middle',
                    backgroundColor: '#ffde21',
                    position: 'sticky',
                    top: '0',
                    zIndex: 99,
                  }}
                >
                  No
                </th>
                {/* Render kolom lainnya */}
                {columns.map((key) => {
                  // Render kolom "name" dan sisipkan "Chat" setelahnya
                  if (key === 'name') {
                    return (
                      <React.Fragment key={key}>
                        <th
                          style={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            padding: '10px',
                            minWidth: '150px',
                            verticalAlign: 'middle',
                            backgroundColor: '#ffde21',
                            position: 'sticky',
                            top: '0',
                            zIndex: 99,
                          }}
                        >
                          {columnHeaders[key] || key}
                        </th>
                        <th
                          style={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            padding: '10px',
                            minWidth: '100px',
                            verticalAlign: 'middle',
                            backgroundColor: '#ffde21',
                            position: 'sticky',
                            top: '0',
                            zIndex: 99,
                          }}
                        >
                          {columnHeaders['whatsapp'] || 'Chat'}
                        </th>
                      </React.Fragment>
                    );
                  }

                  // Abaikan kolom "whatsapp" di iterasi utama
                  if (key === 'whatsapp') {
                    return null;
                  }

                  // Render kolom lainnya
                  return (
                    <th
                      key={key}
                      style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        padding: '10px',
                        minWidth: getColumnMinWidth(key), // Menggunakan fungsi untuk menentukan minWidth
                        verticalAlign: 'middle',
                        backgroundColor: '#ffde21',
                        position: 'sticky',
                        top: '0',
                        zIndex: 99,
                      }}
                    >
                      {columnHeaders[key] || key}
                    </th>
                  );
                })}
              </tr>
              <tr>
                {/* Filter untuk nomor urut */}
                <th></th>
                {/* Render kolom lainnya */}
                {columns.map((key) => {
                  // Filter untuk kolom "name" dan tambahkan filter kosong untuk "Chat"
                  if (key === 'name') {
                    return (
                      <React.Fragment key={key}>
                        <th>
                          <Input
                            type="text"
                            name={key}
                            value={filters[key]}
                            onChange={handleFilterChange}
                            placeholder={`Filter ${columnHeaders[key] || key}`}
                            size="sm"
                            style={{
                              marginTop: '5px',
                              padding: '5px',
                              fontSize: '12px',
                              textAlign: 'center',
                            }}
                          />
                        </th>
                        {/* Filter kosong untuk kolom "Chat" */}
                        <th></th>
                      </React.Fragment>
                    );
                  }

                  // Abaikan filter untuk kolom "whatsapp"
                  if (key === 'whatsapp') {
                    return null;
                  }

                  // Filter untuk kolom lainnya
                  return (
                    <th key={key}>
                      <Input
                        type="text"
                        name={key}
                        value={filters[key]}
                        onChange={handleFilterChange}
                        placeholder={`Filter ${columnHeaders[key] || key}`}
                        size="sm"
                        style={{
                          marginTop: '5px',
                          padding: '5px',
                          fontSize: '12px',
                          textAlign: 'center',
                        }}
                      />
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {memoizedFilteredData.map((user, index) => (
                <tr key={user.id} style={{ cursor: 'pointer' }}>
                  {/* Kolom nomor urut */}
                  <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{index + 1}</td>

                  {columns.map((key) => {
                    // Render kolom "name"
                    if (key === 'name') {
                      return (
                        <React.Fragment key={key}>
                          <td style={{ verticalAlign: 'middle' }}>{user[key]}</td>
                          {/* Kolom "Chat" ditempatkan setelah "name" */}
                          <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                            <Button
                              color="success"
                              size="sm"
                              onClick={() => handleWhatsApp(user.phone, user.name)}
                            >
                              <FaWhatsapp />
                            </Button>
                          </td>
                        </React.Fragment>
                      );
                    }

                    // Abaikan rendering kolom "Chat" (whatsapp) di iterasi utama
                    if (key === 'whatsapp') {
                      return null;
                    }

                    // Render kolom lainnya
                    return (
                      <td key={key} style={{ verticalAlign: 'middle' }}>
                        {user[key]}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>

          </Table>
        </div>
      )}
    </div>
  );
};

export default OpenHouseUpi;
